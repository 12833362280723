import React, { useEffect, useLayoutEffect, useState } from "react"
import Image from "next/image"
import Link from "next/link"
import { AnimatePresence, motion } from "framer-motion"

import SvgMenu from "/public/icons/menu.svg"
import SvgClose from "/public/icons/close.svg"

export const NavBar: React.FC = (props) => {
  const [navbar, setNavbar] = useState(false)
  const [navbarLogo, setNavbarLogo] = useState("")

  // Menu
  const [menu, setMenu] = React.useState(false)
  const menuToggle = () => setMenu(!menu)

  //Background Change
  const changeBackground = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  //Logo Change,-
  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo("")
    } else {
      setNavbarLogo("")
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
    changeLogo()
    window.addEventListener("scroll", changeLogo)

    return () => {
      window.removeEventListener("scroll", changeBackground)
      window.removeEventListener("scroll", changeLogo)
    }
  }, [])

  return (
    <>
      <nav className={"navbar " + (navbar ? " nav-bg" : "")}>
        <div className='inner'>
          <div className='left'>
            <Link href='/'>
              <div className='logo-wrapper'>
                <Image src='/ruby-logo.png' alt='Ruby Logo' width={30} height={30} />
                <h5>Ruby</h5>
              </div>
            </Link>
          </div>

          <div className='center'>
            <Link href='/features'>How it works</Link>
            <Link href='/contact'>Support</Link>
            <Link href='/about'>About</Link>
            <Link href='/blog'>Blog</Link>
          </div>

          <div className='right'>
            <Link href='/download'>
              <button className='btn-md-label bg-primary secondary round'>
                Get Ruby
              </button>
            </Link>
            <button className='btn-md-icon bg-highlight round mob' onClick={menuToggle}>
              {!menu == true ? <SvgMenu /> : <SvgClose />}
            </button>
          </div>
        </div>
      </nav>
      <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
        {menu && <Menu isOpen={menu} onClose={menuToggle} />}
      </AnimatePresence>
    </>
  )
}

// Menu --------------------------------
type menuProps = {
  isOpen: boolean
  onClose: () => void
}

export const Menu: React.FC<menuProps> = (props) => {
  const menuAnimation = {
    hidden: {
      y: "-20px",
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.3,
      },
    },
    show: {
      y: "0",
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.3,
      },
    },
  }

  const allItems = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const items = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  if (!props.isOpen) return null
  return (
    <motion.div
      className='main-menu '
      variants={menuAnimation}
      initial='hidden'
      animate='show'
      exit='hidden'
    >
      <div className='wrapper'>
        <motion.div className='links' variants={allItems}>
          <motion.div variants={items}>
            <Link href='/features'>How it works</Link>
          </motion.div>

          <motion.div variants={items}>
            <Link href='/contact'>Support</Link>
          </motion.div>

          <motion.div variants={items}>
            <Link href='/about'>About</Link>
          </motion.div>

          <motion.div variants={items}>
            <Link href='/blog'>Blog</Link>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}
